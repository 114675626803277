<template>
  <div class="wrapper">
    <div class="dialog w-100vw h-85vh w-500px mt--15vh">
      <div class="text-center font-size-12px border-top-radius-15px"
        style="background-color: #ffefd7;height: 50px;line-height: 30px;color: #4160ab;">
        <van-icon name="shield-o" />
        翻车退款·在线工单·5万人用户群·给你更多售后保障
      </div>
      <div class="content padding-15px border-top-radius-15px border-bottom-radius-15px relative" @click.stop>
        <div class="cut-off-rule">
          <div class="flex">
            <div class="flex-center">
              <van-image radius="3px" width="240" height="100" :src="selectArea.img_path" />
            </div>
            <div class=""
              style="display: flex;flex-direction: column;flex-wrap: nowrap;justify-content: space-between;padding-left: 10px;">
              <div class="font-weight-600" style="font-size: 20px">
                {{ info.name || '' }}
              </div>
              <span style="text-decoration: line-through;font-size: 0.7em;color: gray;"
                v-if="selectedOption.fake_price">￥{{ selectedOption.fake_price }}</span>
              <div class="theme-color flex items-center">
                <span class="font-size-16px" style="margin: 0 5px">¥</span>
                <span class="font-weight-600" style="font-size: 18px;">{{ (selectedOption.price || 0).toFixed(2)
                  }}</span>
              </div>
              <div class="font-size-12px" style="color: #969799" v-if="info.id != 11">
                已选{{ selectedOption.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="cut-off-rule" v-if="info.areas.length > 0">
          <div class="font-weight-600 font-size-16px" style="padding-bottom: 10px">
            {{ miniTitle.text1 }}
          </div>
          <div class="flex" style="flex-wrap: wrap;">
            <div :class="selectArea.id == area.id
                ? 'on-tag-block tag-block font-size-14px'
                : 'tag-block font-size-14px'" v-for="(area, index) in info.areas" :key="index" @click="onArea(area)">
              {{ area.name || '' }}
            </div>
          </div>
        </div>

        <div class="cut-off-rule" v-if="option.length > 0">
          <div class="font-weight-600 font-size-16px" style="padding-bottom: 10px">
            {{ miniTitle.text2 }}
          </div>
          <div class="flex" style="flex-wrap: wrap;">
            <div :class="selectedOption.id == item.id
                ? 'on-tag-block tag-block font-size-14px'
                : 'tag-block font-size-14px'" v-for="(item, index) in option" :key="index" @click="switchData(item)">
              {{ item.name || '' }}
            </div>
          </div>

          <!-- 商品框底下红色的提示文字 -->
          <div class="tip-text" v-if="selectArea.alert">{{ selectArea.alert }}</div>

          <!-- 商品框底下额外的扩展信息 -->
          <div v-if="selectedOption.type == 2">
            <van-field v-model="remark.email" label="邮箱" placeholder="请备注您的账号邮箱" />
            <van-field v-model="remark.password" label="密码" placeholder="请备注您的账号密码" />
          </div>
          <div v-if="selectedOption.type == 3">
            <van-field v-model="remark.email" label="邮箱" placeholder="请填写您的真实邮箱" />
          </div>
        </div>

        <div class="cut-off-rule" v-if="info.options.length == 0">
          <div class="font-size-14px" style="color: #4160ab;">商品没有货了！请您去看看其他商品吧</div>
        </div>
        <div class="cut-off-rule" v-if="info.options.length > 0">
          <van-button round style="width: 100%" color="linear-gradient(to left, #4160ab, #38bfeb )" @click="toTaobao()"
            v-if="info.id == 11">
            淘宝购买
            <van-icon name="share" />
          </van-button>
          <van-button round style="width: 100%" color="linear-gradient(to left, #4160ab, #38bfeb )" @click="onPay"
            v-else>
            去付款
          </van-button>
        </div>
        <van-icon class="add-cursor absolute" name="cross" color="#9F9F9F" style="top: 15px; right: 15px;"
          @click="onClose" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import globals from "@/utils/globals";

export default {
  name: "OrderDialogVue",
  props: {
    modelValue: {
      type: Number,
      default: 0,
    }
  },
  emits: ['onClose'],
  setup(props, { emit }) {
    const state = reactive({
      info: {
        imgpath: "",
        areas: [],
        options: [],
      },
      option: [],
      selectedOption: {
      },
      selectArea: {},
      remark: {
        email: "",
        password: "",
      },
      miniTitle: {
        text1: "地区",
        text2: "时长",
      },
      code: "",
    });
    const loadRecord = () => {
      globals.get('goods/load/' + props.modelValue, {}, (data => {
        if (data.rc) {
          state.info = data.info;
          state.selectArea = data.info.areas.length > 0 ? data.info.areas[0] : {};
          if (state.info.options.length > 0) {
            state.option = state.info.options[0];
            if (state.option.length > 0)
              state.selectedOption = state.option[0];
          }
          if (state.info.id == 11) {
            state.miniTitle = {
              text1: "类型",
              text2: "面值",
            }
          }
        }
      }))
    };
    const switchData = (e) => {
      state.selectedOption = {
        ...e
      }
    };
    const onArea = (e) => {
      state.selectArea = e;
      state.option = state.info.options.find(imte => imte[0].area_id == e.id);
      switchData(state.option[0]);
    };
    const onPay = () => {
      const remark = (state.remark.email != "" ? "邮箱：" + state.remark.email : "") + (state.remark.password != "" ? ", 密码：" + state.remark.password : "");
      if (state.selectedOption.type == 2 || state.selectedOption.type == 3) {
        if (state.remark.email == "") {
          globals.errorMsg("请填写邮箱");
          return;
        }
      }
      if (state.selectedOption.type == 2) {
        if (state.remark.password == "") {
          globals.errorMsg("请填写密码");
          return;
        }
      }
      let form =
      {
        product_id: state.info.id,
        productoption_id: state.selectedOption.id,
        productoption_name: state.selectedOption.name,
        productarea_id: state.selectArea.id,
        remark: remark,
        code: state.code,
      }
      globals.showLoading();
      globals.post('app-order/submit-order', form, (data) => {
        if (data.rc) {
          sessionStorage.removeItem("promote_code");
          globals.successMsg("订单已提交");
          setTimeout(() => {
            globals.hideLoading();
            emit('onClose', data.data);
          }, 1000);
        }
      })
    };
    const toTaobao = () => {
      window.open(state.selectedOption.remark);
    }
    const onClose = () => {
      emit('onClose', null);
    };
    onMounted(() => {
      loadRecord();
      const code = sessionStorage.getItem("promote_code");
      if (globals.notEmptyStr(code)) {
        state.code = code;
      }
    });
    return {
      ...toRefs(state),
      switchData,
      onPay,
      onArea,
      onClose,
      toTaobao,
    };
  },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.dialog {
  position: fixed;
}

.content {
  background-color: #fff;
  height: 100%;
  margin-top: -20px;
}

.cut-off-rule {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f6f6f6;
}

.tag-block {
  margin: 0 8px 8px 0;
  background-color: #f7f8fa;
  padding: 10px;
}

.tag-block:hover {
  cursor: pointer;
}

.on-tag-block {
  color: white !important;
  background-color: #38bfeb;
}

.border-top-radius-15px {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.area-check {
  font-size: 0.7em;
  border-bottom: 1px solid;
  margin-left: 15px;
}

.area-check:hover {
  color: #00bcd4;
}

.tip-text {
  color: red;
  font-size: 0.6em;
  padding-left: 5px;
}

@media screen and (max-width: 488px) {
  .w-100vw {
    width: 100vw;
  }

  .h-85vh {
    height: 85vh;
  }

  .bottom {
    bottom: 0;
  }

}


@media screen and (min-width: 488px) {
  .border-bottom-radius-15px {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .w-500px {
    width: 500px;
  }

  .mt--15vh {
    margin-top: -15vh;
  }

}
</style>