<template>
  <div class="flex-center " style="width: 100%;height: 380px;background-color: #0f172a;color: #fff">
    <div class="bottom-nav" style=" height: 90%">
      <div class="flex justify-around items-center "
        style=" width: 100%; min-height: 120px; border-bottom: 1px solid rgba(139, 149, 155, 0.2); flex-flow: row wrap">
        <div class="item-top" style=" width: 50px; ">
          <div class="flex-center"><van-icon name="underway-o" size="50" color="#fff" /></div>
          <div class="font-size-12px" style="margin-top: 5px; color: #fff">自动发货</div>
        </div>
        <div class="item-top" style=" width: 50px; ">
          <div class="flex-center"><van-icon name="refund-o" size="50" color="#fff" /></div>
          <div class="font-size-12px" style="margin-top: 5px; color: #fff">翻车退款</div>
        </div>
        <div class="item-top" style=" width: 50px; ">
          <div class="flex-center"><van-icon name="exchange" size="50" color="#fff" /></div>
          <div class="font-size-12px" style="margin-top: 5px; color: #fff">无限续费</div>
        </div>
        <div class="item-top" style=" width: 50px; " v-if="false">
          <div class="flex-center"><van-icon name="comment-circle-o" size="50" color="#fff" /></div>
          <div class="font-size-12px" style="margin-top: 5px; color: #fff">在线工单</div>
        </div>
        <div class="item-top" style=" width: 50px; ">
          <div class="flex-center"><van-icon name="service" size="50" color="#fff" /></div>
          <div class="font-size-12px" style="margin-top: 5px; color: #fff">微信客服</div>
        </div>
      </div>

      <div class="flex justify-around items-center" style="width: 90%; margin: 20px 0; padding-left: 15px">

        <div class="font-size-16px font-weight-600 item-bottom font-size-14px"
          style="color: #fff; min-height: 50px;width: 70%;">
          <div class="flex justify-around">
            <div>“</div>
            <div>{{ textInfo.hitokoto }}</div>
            <div>”</div>
          </div>
          <div style="text-align: right;margin-top: 20px;">——&nbsp;&nbsp;{{ textInfo.from_who || textInfo.creator }}「{{
          textInfo.from }}」</div>
        </div>

        <div class="font-size-16px font-weight-600 item-bottom" style="color: #fff; width: 100px; min-height: 50px">
          <div>联系我们</div>
          <div class="font-size-12px" style="margin-top: 10px">
            <div style="margin-bottom: 5px" class="pointer" @click="toAbout">公司简介</div>
            <div style="margin-bottom: 5px">
              <span class="add-cursor"><van-icon size="20" name="wechat" /></span>
              <span class="add-cursor" style="margin-left: 15px"><van-icon size="20" name="guide-o" /></span>
              <span class="add-cursor" style="margin-left: 15px"><van-icon size="20" name="play-circle" /></span>
            </div>
          </div>
        </div>
      </div>

      <div class="font-size-12px flex-center">
        <span>@ 2024奈迪商店（爱煲剧） │ 广西柳州爱煲剧云科技有限公司 │ <a href="https://beian.miit.gov.cn" style="color: #fff;"
            target="_blank">桂ICP备2024027275号-1</a> │ <a
            href="https://beian.mps.gov.cn/#/query/webSearch?code=45020002000234" style="color: #fff;"
            target="_blank">桂公网安备45020002000234</a></span>
      </div>

    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import hitokoto from "@/utils/hitokoto"
import globals from "@/utils/globals";

export default {
  name: "BottomNavVue",

  setup() {
    const state = reactive({
      isShow: true,
      textInfo: {
        hitokoto: "Why not fly to the Moon?",
        creator: "Haze",
        from: "Today"
      }
    });
    const toAbout = () => {
      globals.router.push('about');
    };
    onMounted(() => {
      hitokoto.getText((res) => {
        state.textInfo = res;
      });
    });
    return {
      ...toRefs(state),
      toAbout,
    };
  },
};
</script>
<style scoped>
.item-top {
  margin: 5px 30px;
}

.item-bottom {
  white-space: nowrap;
}

@media screen and (min-width: 375px) {
  .bottom-nav {
    width: 90vw;
  }

  .item-bottom:nth-child(2) {
    display: none;
  }

  .item-bottom:nth-child(3) {
    display: none;
  }
}

@media screen and (min-width: 425px) {}

@media screen and (min-width: 625px) {
  .bottom-nav {
    width: 65vw;
  }

  .item-bottom:nth-child(2) {
    display: block;
  }

  .item-bottom:nth-child(3) {
    display: block;
  }
}

.pointer:hover {
  color: gray;
  cursor: pointer;
}
</style>