import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/:code',
    name: 'homecode',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/Signin.vue')
  },
  {
    path: '/pay-success',
    name: 'OrderPaySuccess',
    component: () => import('../views/OrderPaySuccess.vue')
  },
  {
    path: '/about',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/business/User.vue'),
    children: [
      {
        path: 'giftCard',
        component: () => import('../views/business/user/GiftCard.vue'),
      },
      {
        path: 'order',
        component: () => import('../views/business/user/Order.vue'),
      },
      {
        path: 'share',
        component: () => import('../views/business/user/Share.vue'),
      },
      {
        path: 'useredit',
        component: () => import('../views/business/user/UserEdit.vue'),
      },
      {
        path: 'withdraw',
        component: () => import('../views/business/user/Withdraw.vue'),
      },
      {
        path: 'my-accounts',
        component: () => import('../views/business/user/MyAccounts.vue'),
      },
      {
        path: 'my-wallet',
        component: () => import('../views/business/user/MyWallet.vue'),
      },
      {
        path: 'support',
        component: () => import('../views/business/user/Support.vue'),
        children: [
          {
            path: 'new',
            component: () => import('../views/business/user/support/New.vue'),
          },
          {
            path: 'details/:id',
            component: () => import('../views/business/user/support/Details.vue'),
          },
        ]
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
